import React, { useState } from "react";
import { useGlobal } from "reactn";

import SearchBar from "components/SearchBar";
import TierListLP from "./TierListLP";
import { ReactComponent as LolLogo2 } from "svg/lol-logo-filled.svg";
import { ReactComponent as WowLogo } from "svg/WoW-SelectorLogo.svg";
import { ReactComponent as ValorantLogo } from "svg/valorant-logo.svg";
import { ReactComponent as MarvelRivalsLogo } from "svg/marvel-rivals-logo.svg";
import { ReactComponent as TFTLogo } from "svg/tft-logo.svg";
import { ReactComponent as DeadlockLogo } from "svg/deadlock-logo.svg";
import { ReactComponent as HelldiversLogo } from "svg/helldivers-logo.svg";
import { ReactComponent as TwoxKoLogo } from "svg/2xko-logo.svg";
import GameButton from "../components/GameButton";
import MarvelRivalsPromo from "../components/MarvelRivalsPromo";

const LandingPageContainer = (props) => {
  const { className } = props;

  const [clientRegion] = useGlobal("clientRegion");
  const [region, setRegionValue] = useState(clientRegion);

  const games = [
    {
      logo: <LolLogo2 />,
      link: "/",
      id: "lol",
    },
    {
      logo: <ValorantLogo />,
      link: "/val",
      id: "val",
    },
    {
      logo: <MarvelRivalsLogo />,
      link: "/marvel-rivals",
      id: "mr",
    },
    {
      logo: <TFTLogo />,
      link: "/tft/comps",
      id: "tft",
    },
    {
      logo: <DeadlockLogo />,
      link: "/deadlock",
      id: "deadlock",
      showBeta: true,
    },
    {
      logo: <WowLogo />,
      link: "/wow",
      id: "wow",
    },
    {
      logo: <HelldiversLogo />,
      link: "/hd2",
      id: "helldivers",
    },
    {
      logo: <TwoxKoLogo />,
      link: "/",
      id: "2xko",
      showSoon: true,
    },
  ];

  return (
    <div className={`landing-page-container_mobile ${className}`}>
      <div className="all-landing-page-content content-side-padding">
        <div className="landing-background">
          <div className="overlay" />
          <div className="background-image" />
        </div>
        <MarvelRivalsPromo />
        <div className="landing-header">
          <img className="ugg-logo" src={"https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg"} alt="U.GG" />
        </div>
        <div className="large-search">
          <SearchBar placeholder={"Search a Champion"} searchBarId="mobile-search-landing" autoFocus={false} />
        </div>
        <div className="game-switcher">
          {games.map((x, i) => (
            <GameButton key={i} {...x} />
          ))}
        </div>
        <TierListLP />
      </div>
    </div>
  );
};

export default LandingPageContainer;
