import { window } from "global";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import { MediaQuery } from "@outplayed/responsive";
import DesktopAppLandingPage from "components/Pages/LandingPage/views/DesktopAppLandingPage";
import LandingPageMobile from "components/Pages/LandingPage/views/mobile/LandingPageContainer";

import { usePageTitleAndDesc } from "@ugg/shared/utils/seo-helpers";
import { useQuery } from "@apollo/client";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { AppRoutes, getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";

const LandingPageContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const [loggingIn] = useGlobal("loggingIn");
  const isInitialPage =
    (window && sessionStorage.getItem("initial_page")) === AppRoutes.HOME ||
    (window && sessionStorage.getItem("initial_page")) === AppRoutes.VERIFY;
  const isVerificationLink = location.pathname === AppRoutes.VERIFY;

  useEffect(() => {
    window && sessionStorage.removeItem("initial_page");

    // Remove skin ads
    try {
      window.top.document.getElementById("siteSkinContainer").remove();
    } catch (e) {}
  }, []);

  const {
    loading: loadingUserState,
    data: userState,
    error: errorUserState,
  } = useQuery(GET_USER_STATE, { fetchPolicy: "network-only" });

  const { getState } = userState || {};
  const { lolSettings } = getState || {};
  const { summoners } = lolSettings || {};
  const { riotUserName, riotTagLine, regionId } = (summoners && summoners[0]) || {};
  const canRedirectToProfile = isInitialPage;

  const {
    loading: loadingSummonerProfile,
    data: summonerProfile,
    error: errorSummonerProfile,
  } = useSummonerProfileInitSimple(regionId, riotUserName, riotTagLine, {}, { skip: !userState });

  useEffect(() => {
    if (canRedirectToProfile) {
      if (isVerificationLink) {
        return;
      }
      if (userState && !loadingSummonerProfile) {
        // Redirect to 404 user summoner profile page
        if (!summonerProfile) {
          history.replace(AppRoutes.NOT_FOUND_PROFILE);
        }
        // Redirect to summoner profile page
        else if (summonerProfile) {
          history.replace(getProfileOverviewUrl(regionId, riotUserName, riotTagLine));
        }
      }
    }
  }, [loadingUserState, loadingSummonerProfile]);

  let content = null;
  if (!window || loggingIn || loadingUserState) {
    content = null;
  } else if (loadingSummonerProfile || (canRedirectToProfile && summonerProfile) || (isVerificationLink && !loggingIn)) {
    content = (
      <div className="flex items-center h-[calc(100vh-(var(--masthead-height)))]">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        <MediaQuery min="TABLET" max="DESKTOP_LARGE" renderNullOnFail>
          <DesktopAppLandingPage />
        </MediaQuery>
        <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE" renderNullOnFail>
          <LandingPageMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {getPageTitleAndDesc("index")}
      {content}
    </React.Fragment>
  );
};

export default LandingPageContainer;
