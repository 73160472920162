import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useResponsive } from "@outplayed/responsive";
import { useUGGPatches } from "@ugg/shared/api/requests/patches";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getLuTierColor, getLuTier, getTierColor } from "@ugg/shared/utils/tier-helpers";
import { RoleS } from "@ugg/shared/utils/role-helpers";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { TIER_LIST_ROLE_OPTIONS } from "@ugg/shared/query-params/filter-options/tier-lists/tier-list";
import { TIER_LIST_PAGES } from "@ugg/shared/pages/tier-list-pages";
import { useTierList } from "@ugg/shared/api/requests/tier-lists/tier-list";
import { TierListChampionInfo, selectWinRate } from "@ugg/shared/api/data-parser/tier-lists/tier-list";
import { AppRoutes, getChampionBuildUrl } from "@ugg/shared/routes/app-routes";

const GridBlock = (props: { page: TIER_LIST_PAGES; children: React.ReactNode }) => {
  const { page, children } = props;

  const foundRole = TIER_LIST_ROLE_OPTIONS.find((roleOption) => {
    return roleOption.value === page;
  });

  if (!foundRole) {
    return null;
  }

  return (
    <div className="px-[12px] py-[12px] rounded-[3px] bg-purple-400">
      <Link className="flex items-center justify-center px-[24px] mb-[12px]" to={{ pathname: foundRole.path }}>
        <div className="flex items-center justify-center text-[12px]">
          <div className="mr-[10px] [&>svg]:w-[18px] [&>svg]:h-[18px]">{foundRole.img}</div>
          <h2 className="m-0 text-[16px] font-bold">{foundRole.label}</h2>
        </div>
      </Link>
      {children}
    </div>
  );
};

const TierChampion = (props: { data: TierListChampionInfo }) => {
  const { getChampionName, getChampionImg, getNormalizedChampionName } = getRiotAssetsContext();
  const { champion_id: championId, tier, role, win_rate: winRate, matches } = props.data;

  return (
    <Link
      className="group flex-1 flex flex-col items-center rounded-[3px] px-[5px] py-[15px] bg-purple-200"
      to={getChampionBuildUrl(getNormalizedChampionName(championId), { role })}
    >
      <div className="relative">
        <div className="flex items-center justify-center w-[50px] h-[50px] rounded-full border-[1px] border-lavender-500 overflow-hidden">
          <img className="scale-[1.1]" src={getChampionImg(championId)} />
        </div>
        <div
          className={`absolute top-[-8px] right-[-12px] flex items-center justify-center w-[32px] h-[32px] rounded-full text-[14px] font-bold leading-[1] bg-lavender-500 ${getLuTierColor(
            tier.stdevs,
          )}`}
        >
          {getLuTier(tier.stdevs)}
        </div>
      </div>
      <div className="mt-[8px] text-[12px] font-bold">{getChampionName(championId)}</div>
      <div className="w-[calc(100%-16px)] h-[1px] my-[10px] px-[8px] bg-[#404064]" />
      <div className="flex flex-col items-center mb-[5px] text-[12px]">
        <div className={getTierColor(winRate)}>
          <strong>{`${Math.round(winRate * 100) / 100}%`}</strong>
        </div>
        <div className="text-[10px] font-medium whitespace-nowrap">
          {numberWithCommas(matches)} <span className="text-lavender-100">Matches</span>
        </div>
      </div>
      <div className="group-hover:bg-[#2963da] flex items-center justify-center w-[44px] h-[18px] px-[12px] rounded-[3px] text-[9px] font-bold text-white bg-accent-blue-400 leading-[1]">
        VIEW
      </div>
    </Link>
  );
};

const TierListLP = () => {
  const { currentBreakpoint } = useResponsive();
  const { data: patches } = useUGGPatches();

  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedParams = validateQueryParams(TIER_LIST_PAGES.DEFAULT_TIER_LIST, {}, true);
  const { data: tierListData, loading, error } = useTierList(TIER_LIST_PAGES.DEFAULT_TIER_LIST);
  const { role_win_rates } = selectWinRate(tierListData, validatedParams);

  const championMax = ["MOBILE_SMALL", "MOBILE_MEDIUM"].includes(currentBreakpoint) ? 3 : 5;
  const displayChampions = (champions: TierListChampionInfo[]) => {
    return (
      <div className="flex justify-between items-center gap-[10px]">
        {champions.map((champion, index) => {
          return <TierChampion key={index} data={champion} />;
        })}
      </div>
    );
  };

  type ChampionData = {
    mid: TierListChampionInfo[];
    top: TierListChampionInfo[];
    adc: TierListChampionInfo[];
    jungle: TierListChampionInfo[];
    support: TierListChampionInfo[];
  };
  const roleData = useMemo(() => {
    const data = (role_win_rates || [])
      .filter((champion) => champion.tier.pick_rate > 1)
      .sort((a, b) => b.tier.stdevs - a.tier.stdevs);

    return data.reduce(
      (acc, curr) => {
        if (curr.role === RoleS.MIDDLE) acc["mid"].push(curr);
        else if (curr.role === RoleS.TOP) acc["top"].push(curr);
        else if (curr.role === RoleS.BOTTOM) acc["adc"].push(curr);
        else if (curr.role === RoleS.JUNGLE) acc["jungle"].push(curr);
        else if (curr.role === RoleS.SUPPORT) acc["support"].push(curr);

        return acc;
      },
      {
        mid: [],
        top: [],
        adc: [],
        jungle: [],
        support: [],
      } as ChampionData,
    );
  }, [role_win_rates]);

  if (!role_win_rates || loading || error) {
    return null;
  }

  return (
    <div className="mt-[32px]">
      <div className="flex items-center justify-between mb-[24px] text-[13px]">
        <div className="flex items-center justify-center text-white font-bold text-[16px]">
          <div className="h-[24px] w-[2px] bg-accent-blue-400 rounded-[2px] mr-[10px]" />
          <strong className="font-bold leading-[1]">U.GG Solo Queue Tier List</strong>
        </div>
        {patches?.[0] && <div className="text-[18px] text-accent-gray-100">{`Patch ${patches[0].replace(/\.|\_/g, ".")}`}</div>}
      </div>
      <div className="flex flex-col gap-[12px]">
        <GridBlock page={TIER_LIST_PAGES.TOP_TIER_LIST}>{displayChampions(roleData.top.slice(0, championMax))}</GridBlock>
        <GridBlock page={TIER_LIST_PAGES.JUNGLE_TIER_LIST}>{displayChampions(roleData.jungle.slice(0, championMax))}</GridBlock>
        <GridBlock page={TIER_LIST_PAGES.MID_TIER_LIST}>{displayChampions(roleData.mid.slice(0, championMax))}</GridBlock>
        <GridBlock page={TIER_LIST_PAGES.ADC_TIER_LIST}>{displayChampions(roleData.adc.slice(0, championMax))}</GridBlock>
        <GridBlock page={TIER_LIST_PAGES.SUPPORT_TIER_LIST}>{displayChampions(roleData.support.slice(0, championMax))}</GridBlock>
      </div>
      <div className="flex justify-center mt-[24px]">
        <Link
          className="w-[40%] p-[12px] rounded-[3px] text-center font-bold text-white text-[14px] bg-accent-blue-400 hover:bg-[#2963da]"
          to={{ pathname: AppRoutes.DEFAULT_TIER_LIST }}
        >
          Show Full Tier List
        </Link>
      </div>
    </div>
  );
};

export default TierListLP;
