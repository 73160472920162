import { ReactComponent as RightArrow } from "svg/right-arrow-head.svg";

const MarvelRivalsPromo = () => {
  return (
    <a href="https://u.gg/marvel-rivals/tier-list?utm_source=ugghometopbanner" className="promo-container">
      <div className="promo-content">
        <div className="first-section rivals">
          <div className="first-row">
            <div className="new-tag">NEW</div>
            <span className="top-text">MARVEL RIVALS HAS ARRIVED ON U.GG</span>
          </div>
          <span className="bottom-text">Marvel Rivals data-driven Tier Lists and Leaderboards</span>
        </div>
        <div className="cta-button">
          <span>Check it out</span> <RightArrow className="arrow-icon" />
        </div>
      </div>
    </a>
  );
};

export default MarvelRivalsPromo;
